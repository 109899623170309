import React from 'react';
import StyledPicture from './Picture.style';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { css } from '@emotion/react';

const Picture = ({
  images: _images,
  width,
  height,
  alt,
  rewriteToCdn = true,
  lazy = false,
  maxWidth,
  maxHeight,
  ...p
}) => {
  const srcSet = useMemo(() => {
    const images = { ..._images };
    if (Object.keys(images).length === 1 && images[1] && width && height) {
      const regexes = [];
      if (rewriteToCdn)
        regexes.push([
          /^https?:\/\/www.autokan.nl\/hubfs\/([^?]*?([^?/]+\.(jpe?g|png|webp)))/,
          (mObj) => [
            `https://www.autokan.nl/hs-fs/hubfs/${mObj[1]}`,
            mObj[1],
            mObj[2],
          ],
        ]);
      regexes.push([
        /^(https?:\/\/.+?.hubspotusercontent.+?\/)hubfs\/(\d+)\/([^?]*?([^?/]+\.(jpe?g|png|webp)))/,
        (mObj) => [
          `${mObj[1]}hub/${mObj[2]}/hubfs/${mObj[3]}`,
          mObj[3],
          mObj[4],
        ],
      ]);
      regexes.push([
        /^(https?:\/\/.+?.hubspotusercontent.+?\/)hub\/(\d+)\/hubfs\/([^?]*?([^?/]+\.(jpe?g|png|webp)))/,
        (mObj) => [
          `${mObj[1]}hub/${mObj[2]}/hubfs/${mObj[3]}`,
          mObj[3],
          mObj[4],
        ],
      ]);
      let baseUrl;
      let path;
      let file;
      for (const [regex, onMatch] of regexes) {
        const mObj = images[1].match(regex);
        if (mObj) {
          [baseUrl, path, file] = onMatch(mObj);
        }
      }

      if (baseUrl && path && file) {
        images[1] = `${baseUrl}?width=${width * 1}&height=${
          height * 1
        }&name=${file}`;
        images[2] = `${baseUrl}?width=${width * 2}&height=${
          height * 2
        }&name=${file}`;
        images[3] = `${baseUrl}?width=${width * 3}&height=${
          height * 3
        }&name=${file}`;
      }
    }
    return Object.entries(images)
      .map(([descriptor, src]) => {
        return [descriptor, src];
      })
      .sort(([a], [b]) => a - b);
  }, [JSON.stringify(_images)]);

  return (
    <StyledPicture {...p} maxWidth={maxWidth} maxHeight={maxHeight}>
      <source
        srcSet={srcSet
          .map(([descriptor, src]) => {
            if (descriptor === '1') return src;
            if (!`${descriptor}`.match(/[a-z]+$/)) {
              descriptor = `${descriptor}x`;
            }
            return `${src} ${descriptor}`;
          })
          .join(', ')}
      />
      <img
        css={css`
          --source-width: ${width}px;
          --source-height: ${height}px;
        `}
        width={width}
        height={height}
        src={srcSet[0][1]}
        alt={alt}
        loading={lazy ? 'lazy' : undefined}
      />
    </StyledPicture>
  );
};
Picture.propTypes = {
  images: PropTypes.objectOf(PropTypes.string).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  alt: PropTypes.string.isRequired,
};
export default Picture;
