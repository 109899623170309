import { css } from '@emotion/react';
import styled from '@emotion/styled';

export default styled.picture`
  display: inline-block;
  overflow: hidden;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'unset')};
  img {
    display: block;
    object-fit: contain;
  }
`;
